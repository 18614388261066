export const siteName = "Onyaka Sanal Market";
export const siteNameUpper = "ONYAKA SANAL MARKET";

export const siteMailList = [
  {
    mail: "sanal@onyaka.com.tr",
    subject: "Bize Mail Bırakın",
  },
];

export const sitePhoneList = [{ phone: "444 73 21", info: "" }];

export const eklenenMiktarKatsayisi = 0.5;
