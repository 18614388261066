//AdetInputveSepeteEkleButton kopyalandı
//sadece arama dropdownu içinde gosteriliyor
//urun eklendi ve stok yok uyarıları için navbar içine bu componentler eklendi
//ve buradan emit ile oraya bilgi gonderiliyor. 
//ve normal komponente gore biraz daha kucultuldu width ve height degerleri.

Bu sayfadaki puf noktalar:
1 - Mobil uyumluluk icin @media kullanildi
2 - Kullanici kayitli mi degil mi kontrolu(giris yap ve kayit ol butonlari saklanir, profilim butonu gorunur) yapiliyor
<template>
<div v-cloak >
    <!--Navbar -->
    <nav class="mb-1 navbar navbar-expand-lg navbar-dark lighten-1" :style="navbarColor">
        <router-link class="nav-brand" to="/">
            <span class="navbar-brand"><img src="@/assets/img/logo-footer.png" alt=""></span>
        </router-link>

        <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent-555"
                aria-controls="navbarSupportedContent-555" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button> -->
        <div class="collapse navbar-collapse" id="navbarSupportedContent-555">
            <!-- <ul class="navbar-nav mr-auto">
                    <li class="nav-item">
                        <router-link class="nav-link" to="/sepet">
                            <i class="fas fa-cart-arrow-down"></i>{{ $t('Cart') }}
                            <span class="badge badge-danger">{{ sepettekiUrunSayisi }}</span>
                        </router-link>
                    </li>
                </ul> -->
            <ul class="navbar-nav ml-auto nav-flex-icons">
                <form class="form-inline arama-alan">
                    <div class="my-0">
                        <img class="search-svg" src="@/assets/svg/Search.svg" alt="">
                        <!-- <input id="ara" v-on:keyup.enter="onEnter" @keyup="inputChanged"  @focus="fetchGecmisArama()" class="mr-sm-2" @input="aramaAlan" v-model="aratilanUrun" onkeypress="return event.keyCode != 13;" type="text" :placeholder="$t('Search')" autocomplete="off" aria-label="Search">-->

                        <input id="ara" v-on:keyup.enter="onEnter" @keyup="onChangeDebounced"  class="mr-sm-2" @input="aramaAlan" v-model="aratilanUrun" onkeypress="return event.keyCode != 13;" type="text" :placeholder="$t('Search')" autocomplete="off" aria-label="Search">

                       
                        <div v-if="aramasonucgoster"  >
                            <div v-click-outside="onFocusOut" class="hiddendiv">
                                <!--
                            <div class="gecmis-arama-text">Geçmiş Aramalarım
                                <transition name="fade"><span v-if="aramaConf">En az 3 harf İçermelİ</span></transition>
                            </div>
                            <div v-if="showAramaYukleniyor"><img class="loading" src="@/assets/svg/loading.gif" alt=""></div>

                        -->

                              
                                    <div class="row">
                                        <div class="col-12">

                                            <table class="table table-hover">
                                
                                                <tbody>

                                                    <tr class="tableRow" v-for="gecmis in aramacomplete.response" :key="gecmis.id">
                                                       
                                                        <router-link  :to="{ name : 'UrunPage', params : {urun_ad:gecmis.ad, g_id:gecmis.grup, g_ad:gecmis.grupad, altg_id:gecmis.altgrup, altg_ad:gecmis.altgrupad} }">
                                                   
                                                        <!-- arama yaparken urun bilgisi icinde stokkod degil de stok_kodu bilgisi oldugu icin sepet buttonda gosterirken resim bulunsun diye eklenen kontrol -->
                                                        <th v-if="gecmis.barkod!=null" scope="row">
                                                            <div class="resim-border d-flex justify-content-center align-items-center">
                                                                <img v-if="gecmis.barkod.length < 8" :src="imagecompany + ushp + '/' + gecmis.barkod + '.jpeg?' + gecmis.imageupdate" class="img-width" alt="" />
                                                                <img v-else :src="image + gecmis.barkod + '.jpeg'" class="img-width" alt="" />
                                                            </div>
                                                        </th>
                                                        <th v-if="gecmis.barkod==null" scope="row">
                                                            <div class="resim-border d-flex justify-content-center align-items-center">
                                                                <img :src="image + gecmis.barkod + '.jpeg'" class="img-width" alt="" />
                                                            </div>
                                                        </th>
                                                        <td id="resp-urunad">
                                                            <div class="aramaName mt-1 ml-n1 mr-n1">{{ gecmis.ad }}</div>
                                                            
                                                          <div class="aramaFiyat"><span><b>{{ (gecmis.satisfiyat ).toFixed(2) }} ₺</b></span></div>
                                                        </td>
                                                       
                                                    </router-link>

                                                        <td>
                                                          
                                                            <div class="urunler-adet-input mt-1">
                                                <AdetInputSepeteEkleArama  :liste="gecmis" @uruneklendi="uruneklendi" @stoksayisimesajMi="stoksayisimesajMi" />
                                            </div>

                                                        </td>

                                                
                                                    </tr>
                                                    

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                               

                                <!--
                            <div v-for="gecmisarama in gecmisArama.response" :key="gecmisarama.id">
                                <div v-if="gecmisarama.gecmis_aramalar==''">- Henüz bulunmamaktadır.</div>
                                <div v-for="gecmis in gecmisarama.gecmis_aramalar" :key="gecmis.id">
                                    <div @mousedown="gecmisKelimeyiAra(gecmis)">
                                        {{gecmis.kelime}}
                                        <span class="float-right pr-2"><i class="fas fa-external-link-alt"></i></span>
                                    </div>
                                </div>
                            </div>

                        -->

                            </div>
                        </div>
                        <!-- <div v-if="aramaConf" class="arama-conf">En az 3 harf olmalıdır.</div> -->
                    </div>
                    <button @click="araButton()" :disabled="isDisabled()" type="button" class="btn btn-md waves-effect waves-light" :style="navbar2Color">{{ $t('Search') }}</button>
                    <!-- <router-link :to="{ name : 'AramaPage', params : {aratilan:aratilanUrun+'='} }" class="btn btn-outline-white btn-md btn-rounded waves-effect waves-light my-2 my-sm-0 ml-3">{{ $t('Search') }}</router-link> -->
                    <!-- <button class="btn btn-outline-white btn-md btn-rounded waves-effect waves-light my-2 my-sm-0 ml-3" type="submit">{{ $t('Search') }}</button> -->
                </form>
                <li v-if="showLoginButtons" class="nav-item mb-2 mb-md-0">
                    <router-link to="/girisyap" href="#" type="button" class="btn 
                        btn-md btn-navbar waves-effect waves-light girisyap-button" :style="sepetButtonColor">Girİş yap veya Üye ol</router-link>
                </li>
                <!-- Kullanici kayit olusturmussa gorunecek START -->
                <li v-if="showProfil" class="nav-item mb-2 mb-md-0">
                    <router-link to="/profilim" href="#" type="button" class="btn  
                        btn btn-navbar waves-effect waves-light profilim-button" :style="sepetButtonColor">{{ $t('My Profile') }}</router-link>
                </li>
                <!-- Kullanici kayit olusturmussa gorunecek END -->

            </ul>
        </div>

        <UrunEklendiMesaji :showUrunEklendi="showUrunEklendi" :eklenenUrun="eklenenUrun" :eklenenUrunAdeti="eklenenUrunAdeti"  :myTimeOut="myTimeOut" :birimAdetKg="birimAdetKg" />
        <StokSayisiMesaji :showStokSayisiMesaji="showStokSayisiMesaji" @stoksayisimesaj="stoksayisimesajMi" :myTimeOut="myTimeOut" />
        <UrunFarkliDepodan :showFarkliDepo="showFarkliDepo" @farklidepo="farklidepoMu" :myTimeOut="myTimeOut" />
      

    </nav>
    <!--/.Navbar -->

    <NavbarTwo />

</div>
<!-- <TumKategoriler /> -->
</template>

<script>
import axios from 'axios'
import vClickOutside from 'v-click-outside'
// import TumKategoriler from "@/components/TumKategoriler"
import NavbarTwo from "@/components/NavbarTwo"
import AdetInputSepeteEkleArama from "@/components/AdetInputSepeteEkleArama"

import UrunEklendiMesaji from "@/components/UrunEklendiMesaji"
import StokSayisiMesaji from "@/components/StokSayisiMesaji"
import UrunFarkliDepodan from "@/components/UrunFarkliDepodan"



import {
    mapGetters
} from 'vuex'

export default {

    directives: {
      clickOutside: vClickOutside.directive
    },

    data() {
        return {
            showLoginButtons: true, // kaydi olmayan kullaniciya gorunecek giris yap ve kayit ol butonlarinin show/hide-i icin
            showProfil: false, // kaydi olan kullanicinin gorecegi profilim butonunun show/hide-i icin
            geciciSepet: '',
            sepettekiUrunSayisi: '',
            aratilanUrun: '',
            aramaConf: false,
            disabled: false,
            // gecmis aramalar icin
            gecmisArama: [],
            aramacomplete: [],
            errors: [],
            showAramaYukleniyor: true,
            aramasonucgoster: false,
            // cookie bilgileri START
            uid: "",
            utel: "",

            lastSearchTime: 0,
           

            birimAdetKg : "",
            showUrunEklendi : false, // urun eklendi mesajinin show/hide-i icin
            eklenenUrun : '', // eklenecek urunun adi buraya yazilir
            eklenenUrunAdeti : '', // eklenecek urunun adeti buraya yazilir
            showFarkliDepo : false, // ayni urun farkli depolardan eklenmek istenirse mesajin show/hide-i icin
            showStokSayisiMesaji : false, // urun adet sayisi stoktan fazlaysa mesajin show/hide-i icin
            myTimeOut : "", // urun eklendi mesajinin settimeout degeri buraya yazilip, mesaj hover oldugunda bu deger sifirlanir

            // cookie bilgileri END
        }
    },
    
    components: {
        // TumKategoriler,
        NavbarTwo,
        AdetInputSepeteEkleArama,
        UrunEklendiMesaji,
        StokSayisiMesaji,
        UrunFarkliDepodan,
   

    },
    mounted() {
        // sepetButton-a her click yapildiginda tetiklenir, yani icerigin degistigini anlayabiliyoruz
        window.addEventListener('click', this.urunSayisi)

    },
    beforeUnmount() {
        window.removeEventListener('click', this.urunSayisi)
    },
    computed: {
        ...mapGetters(["imagecompany", "image", "navbarColor", "serverRequest", "navbar2Color", "sepetButtonColor"]),
    },
    methods: {

        uruneklendi(params){ // UrunEklendiMesaji.vue-dan gelen emit burada mesajin gosterilme durumu icin
         

            this.showUrunEklendi = params.showMsg
            this.eklenenUrun = params.urunAd
            this.myTimeOut = params.timeout
            this.eklenenUrunAdeti = params.adet
            this.birimAdetKg = params.birim

            if(this.showUrunEklendi==true){
                            this.myTimeOut = setTimeout(() => this.showUrunEklendi = false, 3500)
                        }


        },
        stoksayisimesajMi(params){ // StokSayisiMesaji.vue-dan gelen emit burada mesajin gosterilme durumu icin
           
         
            this.showStokSayisiMesaji = params.showMsg
            this.myTimeOut = params.timeout

            if(this.showStokSayisiMesaji==true){
                            this.myTimeOut = setTimeout(() => this.showStokSayisiMesaji = false, 3500)
                        }

        },
        farklidepoMu(params){ // UrunFarkliDepodan.vue-dan gelen emit burada mesajin gosterilme durumu icin
            this.showFarkliDepo = params
        },
     
     

        onFocusOut: function () {
            console.log("test")

            this.aramasonucgoster = false;

        },

        modalKapat: function(){

            this.aramasonucgoster = false;
        },

        debounce(fn, wait) {
            let timer;
            return function (...args) {
                if (timer) {
                    clearTimeout(timer); // clear any pre-existing timer
                }
                const context = this; // get the current context
                timer = setTimeout(() => {
                    fn.apply(context, args); // call the function if time expires
                }, wait);
            }
        },

        onEnter: function () {

            this.aramasonucgoster = false

            this.$router.push({
                name: 'AramaPage',
                params: {
                    aratilan: this.aratilanUrun + '='
                }
            })

            document.activeElement.blur();

        },

        gecmisKelimeyiAra(gecmis) {
            this.aratilanUrun = gecmis.kelime
            this.$router.push({
                name: 'AramaPage',
                params: {
                    aratilan: this.aratilanUrun + '='
                }
            })
        },
        isDisabled() {
            if (this.aratilanUrun != "" && this.aratilanUrun && this.aramaConf != true) {

                return this.disabled = false

            } else {

                this.aramasonucgoster = false
                this.aramacomplete = []
                return this.disabled = true
            }
        },
        aramaAlan() {
            if (this.aratilanUrun.length < 1 && this.aramacomplete.length == 0) {
                this.aramasonucgoster = false
                this.aramaConf = true
            } else {
                
                this.aramaConf = false 
                this.aramasonucgoster = true
                
            }
        },
        getUid_Udep() {
            this.uid = this.decryptCookie("uid")
            this.utel = this.decryptCookie("utel")
            this.udep = this.decryptCookie("udep")
            this.ushp = this.decryptCookie("ushp")
        },
        araButton() {
            this.$router.push({
                name: 'AramaPage',
                params: {
                    aratilan: this.aratilanUrun + '='
                }
            })
        },
        urunSayisi() { // toplam tutar da buradan aliniyor
            if (localStorage.getItem("sepet") == null) {
                localStorage.setItem("sepet", "[]")
            }
            var localSpt = (JSON.parse(localStorage.getItem("sepet"))) // localstorage "sepet" icindeki veriler parse edilerek localspt-e yazilir
            this.geciciSepet = localSpt // localSpt-deki veriler geciciSepet-e yazilir ve artik tum islemleri localstorage uzerinden degil de geciciSepet uzerinden yapilacak hale getirilir burada
            this.sepettekiUrunSayisi = this.geciciSepet.length // sepetteki urun sayisini almak icin
        },
        async fetchGecmisArama() {
            try {
                const formData = new FormData();
                formData.append('f', 'get_gecmis_arama');
                formData.append('user', this.utel);
                formData.append('uid', this.uid);
                const response = await axios.post(this.serverRequest, formData, {
                    headers: {
                        // 'Accept': 'application/json',
                        // "Access-Control-Allow-Origin": "*",
                        // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                    }
                });
                this.gecmisArama = response.data
                this.showAramaYukleniyor = false
            } catch (error) {
                this.errors.push(error)
            }
            // console.log("sorgu yapildi")
        },

        async fetchAramaComplete() {
            if (this.aratilanUrun.length > 1)
                try {
                    const formData = new FormData();
                    formData.append('f', 'complete_aranan_kelime');
                    formData.append('aratilan_kelime', this.aratilanUrun);
                    formData.append('depo', this.udep);
                    const response = await axios.post(this.serverRequest, formData, {
                        headers: {
                            // 'Accept': 'application/json',
                            // "Access-Control-Allow-Origin": "*",
                            // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
                            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                        }
                    });
                    this.aramacomplete = response.data
                    this.showAramaYukleniyor = false

                    if(this.aramacomplete.length == 0)
                    {
                        this.aramasonucgoster = false
                    }

                    //   console.log(this.aramacomplete.response)
                } catch (error) {
                    this.errors.push(error)
                }
            // console.log("sorgu yapildi")
        },

    },
    created() {
        this.urunSayisi()
        // var getCookie = document.cookie
        // getCookie.includes("uid") // onceden kontrol tek uid ile yapiliyordu
        this.getUid_Udep()
        var getUserInLocal = localStorage.getItem("user")
        if (getUserInLocal != null) { // local-de giris yaptiktan sonra donen user bilgisi var mi diye kontrol edilir
            this.showLoginButtons = false // varsa login button-lari hide olur
            this.showProfil = true // profilim button-u show olur
        }

        this.onChangeDebounced = this.debounce(event => {

            console.log('changed value:', event.target.value);

            this.fetchAramaComplete()

            // call fetch API to get results
        }, 200);

    }
}
</script>

<style scoped>
[v-cloak]>* {
    display: none;
}

/* gecmis aramalar icindeki girilmesi gereken en az harf mesaji START */
.fade-enter-active,
.fade-leave-active {
    transition: opacity .3s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

/* gecmis aramalar icindeki girilmesi gereken en az harf mesaji END */
@media (max-width : 1200px) {
    nav {
        padding-right: 1% !important;
        padding-left: 1% !important;
    }
}

@media (max-width : 1050px) {
    .navbar-nav {
        display: none;
    }
}

@media (max-width : 990px) {
    .navbar {
        display: flex;
        justify-content: center;
    }
}

@media (max-width : 500px) {
    .navbar {
        min-height: 50px !important;
    }

    .navbar-brand {
        margin-top: -15px !important;
        margin-bottom: -15px !important;
    }

    .navbar-brand img {
        width: 150px !important;
    }
}

nav {
    padding-right: 6%;
    padding-left: 6%;
}

.navbar {
    z-index: 10;
    min-height: 90px;
}

.navbar-brand {
    margin-top: -5px;
    margin-bottom: -5px;
    /* border: 1px solid red; */
}

.navbar-brand img {
    width: 250px;
    /* gunkay sanal market logosunda width 95 idi */
    height: 75px;
    object-fit: contain;
    /* gunkay sanal market logosunda object-fit cover idi */

}

.nav-link {
    font-size: 14px;
}

.btn-rounded {
    border-radius: 20px;
}

.badge {
    font-size: 12px;
}

.arama-alan {
    position: absolute;
    left: 51.3%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.arama-alan button {
    position: absolute;
    right: 2px;
    color: white;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 18px 35px;
}

.search-svg {
    position: absolute;
    top: 14px;
    left: 9px;
    width: 20px;
}

#ara {
    width: 520px;
    height: 52px;
    border: 1px solid #dddddd;
    border-radius: 10px;
    outline: none !important;
    padding: 5px 60px 5px 35px;
}

.arama-conf {
    color: rgb(231, 231, 231);
    font-size: 11px;
    position: absolute;
    top: 45px;
    left: 7px;
}

/* input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 1000px white inset !important;
    } */
.hiddendiv {
    
    z-index: 10;
    font-size: 15px;
    position: absolute;
    border: 1px solid #eee;
    padding: 9px;
    border-bottom-width: 5px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 520px; 
    max-height: 400px;
    overflow-y: scroll;
    overflow-x: hidden;
    color: black;
    background: white;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.3);
    cursor: default;

}

.gecmis-arama-text {
    font-size: 14px;
    color: #b1b1b1;
    margin-bottom: 3px;
}

.gecmis-arama-text span {
    float: right;
    font-size: 10px;
    text-transform: uppercase;
    border: 1px solid #ff4444;
    border-radius: 20px;
    color: white;
    background: #ff4444;
    padding: 2px 5px;
}

/*
.hiddendiv div div:hover {
    cursor: pointer;
    background: #eee;
    color: #636363;
    padding-left: 5px;
    margin-left: -5px;
    border-radius: 3px;
}

.hiddendiv div div span {
    display: none;
    color: #636363;
}

.hiddendiv div div:hover span {
    display: block;
}

*/

.loading {
    width: 17px;
    height: 17px;
    margin: 0 -20px 0 10px;
}

.aramaName {
    width: 245px;
    height: 40px;
    font-weight: 400;

}

.aramaFiyat {
    height: 20px;
    font-size: 18px;
    font-weight: 500;
    margin-top: 10px;
    margin-left: -3px;
 


}

.tableRow{
    height: 110px;
  

}

.girisyap-button {
    border-radius: 10px;
    margin-right: 0px;
    margin-left: -64px;
}

.profilim-button {
    border-radius: 10px;
    margin-right: 0px;
}

.resim-border {
    border: 1px solid #eee;
    border-radius: 10px;
    background: white;
    width: 60px;
    height: 80px;

    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}

.img-width {
    max-width: 55px;
    max-height: 65px;
    object-fit: contain;
}

.urunler-adet-input{
        width : 120px;
        padding-left: 8px;
        display: flex;
        justify-content: center;
    }

 

</style>
