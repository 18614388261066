import { render, staticRenderFns } from "./Gruplar.vue?vue&type=template&id=7e24f130&scoped=true"
import script from "./Gruplar.vue?vue&type=script&lang=js"
export * from "./Gruplar.vue?vue&type=script&lang=js"
import style0 from "./Gruplar.vue?vue&type=style&index=0&id=7e24f130&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e24f130",
  null
  
)

export default component.exports